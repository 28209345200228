import { reactive, readonly } from 'vue';

const state = reactive(new Map());

export function useEventBus() {
  const emit = (event, ...args) => {
    const callbacks = state.get(event) || [];
    callbacks.forEach((callback) => callback(...args));
  };

  const on = (event, callback) => {
    const callbacks = state.get(event) || [];
    callbacks.push(callback);
    state.set(event, callbacks);
  };

  return {
    emit,
    on,
  };
}

export default readonly(state);